export const handlePayment = async (
  formData,
  booking_token,
  sessionId,
  flights,
  setIsLoading
) => {
  try {
    const response = await fetch(
      "https://flymoon-backend.vercel.app/payment/create-payment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the server knows you're sending JSON
        },
        body: JSON.stringify({
          name: formData.firstName + " " + formData.lastName, // Concatenate first and last names
          email: formData.email,
          phone: formData.phone,
          country: formData?.nationality, // Use optional chaining in case nationality is undefined
          sessionId: sessionId,
          amount: flights?.conversion?.adults_price, // Use optional chaining for potential undefined values
          booking_token,
        }),
      }
    );
    const data = await response.json();

    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  } catch (error) {
    console.error("Error creating payment: ", error);
  }
};
