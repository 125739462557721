import React from "react";
import { Link } from "react-router-dom";

export default function PaymentSuccess() {
  return (
    <div className="flex flex-col items-center justify-start mt-10 h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <div className="mb-6">
          {/* Success Icon */}
          <svg
            className="w-16 h-16 text-green-500 mx-auto mb-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.707a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-gray-800 mb-5">
          تم الدفع بنجاح!
        </h2>
        <p className="text-gray-600 mb-8 font-semibold">
          شكرا لك على الدفع. لقد اكتملت معاملتك بنجاح.{" "}
        </p>
        <div className="space-x-4">
          {/* <button className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg">
            View Details
          </button> */}
          <Link
            className="bg-blue-700 hover:bg-blue-800 text-white px-6 py-4 rounded-lg"
            to={"/"}
          >
            الصفحة الرئيسيه
          </Link>
        </div>
      </div>
    </div>
  );
}
