import { t } from "i18next";
import React from "react";
import { useSearchType } from "../context/searchTypeContext";

const TypePlane = () => {
  const { searchType, setSearchType } = useSearchType();

  return (
    <div className="flex items-center gap-5  cursor-pointer justify-center">
      {t("wayOptions", { returnObjects: true }).map((opt) => (
        <div
          key={opt.value}
          onClick={() => setSearchType(opt.value)}
          className={`${
            opt.value === searchType
              ? " bg-blue-600 rounded-lg text-white"
              : "text-black bg-white border border-gray-300"
          } px-4 py-1 rounded-lg`}
        >
          <p className="">{opt.label}</p>
        </div>
      ))}
    </div>
  );
};

export default TypePlane;
