import React from "react";
import LocationInput from "./LocationInput";
import { useSearchFlights } from "../../../../context/SearchFlightsContext";
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa";
import DateContainer from "../../../DateContainer";

export default function SearchForm() {
  const { origin, onOrigin, destination, onDestination } = useSearchFlights();
  return (
    <div className="flex flex-col w-full  gap-5 mt-5">
      <LocationInput
        code={origin}
        title={"inputForm.tilte"}
        icon={<FaPlaneDeparture className="text-xl text-gray-700" />}
        placeholder="inputForm.placeHolder"
        onLocation={onOrigin}
      />
      <LocationInput
        code={destination}
        title={"inputTo.tilte"}
        placeholder="inputTo.placeHolder"
        icon={<FaPlaneArrival className="text-xl text-gray-700" />}
        onLocation={onDestination}
      />
      <DateContainer />
    </div>
  );
}
