import React from "react";
import { useSearchFlights } from "../../context/SearchFlightsContext";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import useLocation from "../search/useLocation";

export default function Header({ origin, destination }) {
  return (
    <div>
      <Directions origin={origin} destination={destination} />
    </div>
  );
}

const Directions = ({ origin, destination }) => {
  const { i18n } = useTranslation();
  const [cityOrigin] = useLocation(origin);
  const [cityDestination] = useLocation(destination);
  return (
    <div className="flex items-center justify-start gap-3 font-semibold">
      <p>{cityOrigin}</p>
      {i18n.language === "ar" ? (
        <FaArrowLeftLong className="text-sm" />
      ) : (
        <FaArrowRightLong className="text-sm" />
      )}
      <p>{cityDestination}</p>
    </div>
  );
};
