export function formatDate(dateString, lan) {
  const monthsAr = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let months = lan === "ar" ? monthsAr : monthsEn;

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} - ${months[monthIndex]} ${year}`;
}

export const getLocalStorage = (key) => {
  const data =
    localStorage.getItem(key) === "null" && localStorage.getItem(key) === ""
      ? null
      : localStorage.getItem(key);
  return data;
};

export const countries = [
  { code: "AF", name: { en: "Afghanistan", ar: "أفغانستان" } },
  { code: "AL", name: { en: "Albania", ar: "ألبانيا" } },
  { code: "DZ", name: { en: "Algeria", ar: "الجزائر" } },
  { code: "AS", name: { en: "American Samoa", ar: "ساموا الأمريكية" } },
  { code: "AD", name: { en: "Andorra", ar: "أندورا" } },
  { code: "AO", name: { en: "Angola", ar: "أنغولا" } },
  { code: "AI", name: { en: "Anguilla", ar: "أنغويلا" } },
  { code: "AQ", name: { en: "Antarctica", ar: "أنتاركتيكا" } },
  { code: "AG", name: { en: "Antigua and Barbuda", ar: "أنتيغوا وبربودا" } },
  { code: "AR", name: { en: "Argentina", ar: "الأرجنتين" } },
  { code: "AM", name: { en: "Armenia", ar: "أرمينيا" } },
  { code: "AW", name: { en: "Aruba", ar: "أروبا" } },
  { code: "AU", name: { en: "Australia", ar: "أستراليا" } },
  { code: "AT", name: { en: "Austria", ar: "النمسا" } },
  { code: "AZ", name: { en: "Azerbaijan", ar: "أذربيجان" } },
  { code: "BS", name: { en: "Bahamas", ar: "الباهاما" } },
  { code: "BH", name: { en: "Bahrain", ar: "البحرين" } },
  { code: "BD", name: { en: "Bangladesh", ar: "بنغلاديش" } },
  { code: "BB", name: { en: "Barbados", ar: "باربادوس" } },
  { code: "BY", name: { en: "Belarus", ar: "بيلاروسيا" } },
  { code: "BE", name: { en: "Belgium", ar: "بلجيكا" } },
  { code: "BZ", name: { en: "Belize", ar: "بليز" } },
  { code: "BJ", name: { en: "Benin", ar: "بنين" } },
  { code: "BM", name: { en: "Bermuda", ar: "برمودا" } },
  { code: "BT", name: { en: "Bhutan", ar: "بوتان" } },
  { code: "BO", name: { en: "Bolivia", ar: "بوليفيا" } },
  {
    code: "BQ",
    name: {
      en: "Bonaire, Sint Eustatius and Saba",
      ar: "بونير، سانت يوداس وسابا",
    },
  },
  { code: "BA", name: { en: "Bosnia and Herzegovina", ar: "البوسنة والهرسك" } },
  { code: "BW", name: { en: "Botswana", ar: "بوتسوانا" } },
  { code: "BV", name: { en: "Bouvet Island", ar: "جزيرة بوفية" } },
  { code: "BR", name: { en: "Brazil", ar: "البرازيل" } },
  {
    code: "IO",
    name: {
      en: "British Indian Ocean Territory",
      ar: "إقليم المحيط الهندي البريطاني",
    },
  },
  { code: "BN", name: { en: "Brunei Darussalam", ar: "بروناي" } },
  { code: "BG", name: { en: "Bulgaria", ar: "بلغاريا" } },
  { code: "BF", name: { en: "Burkina Faso", ar: "بوركينا فاسو" } },
  { code: "BI", name: { en: "Burundi", ar: "بوروندي" } },
  { code: "CV", name: { en: "Cabo Verde", ar: "الرأس الأخضر" } },
  { code: "KH", name: { en: "Cambodia", ar: "كمبوديا" } },
  { code: "CM", name: { en: "Cameroon", ar: "الكاميرون" } },
  { code: "CA", name: { en: "Canada", ar: "كندا" } },
  { code: "KY", name: { en: "Cayman Islands", ar: "جزر كايمان" } },
  {
    code: "CF",
    name: { en: "Central African Republic", ar: "جمهورية إفريقيا الوسطى" },
  },
  { code: "TD", name: { en: "Chad", ar: "تشاد" } },
  { code: "CL", name: { en: "Chile", ar: "شيلي" } },
  { code: "CN", name: { en: "China", ar: "الصين" } },
  { code: "CX", name: { en: "Christmas Island", ar: "جزيرة الكريسماس" } },
  {
    code: "CC",
    name: { en: "Cocos (Keeling) Islands", ar: "جزر كوكوس (كيلينغ)" },
  },
  { code: "CO", name: { en: "Colombia", ar: "كولومبيا" } },
  { code: "KM", name: { en: "Comoros", ar: "جزر القمر" } },
  {
    code: "CD",
    name: {
      en: "Congo, Democratic Republic of the",
      ar: "جمهورية الكونغو الديمقراطية",
    },
  },
  { code: "CG", name: { en: "Congo", ar: "الكونغو" } },
  { code: "CK", name: { en: "Cook Islands", ar: "جزر كوك" } },
  { code: "CR", name: { en: "Costa Rica", ar: "كوستاريكا" } },
  { code: "HR", name: { en: "Croatia", ar: "كرواتيا" } },
  { code: "CU", name: { en: "Cuba", ar: "كوبا" } },
  { code: "CW", name: { en: "Curaçao", ar: "كوراساو" } },
  { code: "CY", name: { en: "Cyprus", ar: "قبرص" } },
  { code: "CZ", name: { en: "Czech Republic", ar: "الجمهورية التشيكية" } },
  { code: "DK", name: { en: "Denmark", ar: "الدنمارك" } },
  { code: "DJ", name: { en: "Djibouti", ar: "جيبوتي" } },
  { code: "DM", name: { en: "Dominica", ar: "دومينيكا" } },
  { code: "DO", name: { en: "Dominican Republic", ar: "جمهورية الدومينيكان" } },
  { code: "EC", name: { en: "Ecuador", ar: "الإكوادور" } },
  { code: "EG", name: { en: "Egypt", ar: "مصر" } },
  { code: "SV", name: { en: "El Salvador", ar: "السلفادور" } },
  { code: "GQ", name: { en: "Equatorial Guinea", ar: "غينيا الاستوائية" } },
  { code: "ER", name: { en: "Eritrea", ar: "إريتريا" } },
  { code: "EE", name: { en: "Estonia", ar: "استونيا" } },
  { code: "SZ", name: { en: "Eswatini", ar: "إسواتيني" } },
  { code: "ET", name: { en: "Ethiopia", ar: "إثيوبيا" } },
  { code: "FJ", name: { en: "Fiji", ar: "فيجي" } },
  { code: "FI", name: { en: "Finland", ar: "فنلندا" } },
  { code: "FR", name: { en: "France", ar: "فرنسا" } },
  { code: "GF", name: { en: "French Guiana", ar: "غويانا الفرنسية" } },
  { code: "PF", name: { en: "French Polynesia", ar: "بولينيزيا الفرنسية" } },
  { code: "GA", name: { en: "Gabon", ar: "الغابون" } },
  { code: "GM", name: { en: "Gambia", ar: "غامبيا" } },
  { code: "GE", name: { en: "Georgia", ar: "جورجيا" } },
  { code: "DE", name: { en: "Germany", ar: "ألمانيا" } },
  { code: "GH", name: { en: "Ghana", ar: "غانا" } },
  { code: "GI", name: { en: "Gibraltar", ar: "جبل طارق" } },
  { code: "GR", name: { en: "Greece", ar: "اليونان" } },
  { code: "GL", name: { en: "Greenland", ar: "جرينلاند" } },
  { code: "GD", name: { en: "Grenada", ar: "غرناطة" } },
  { code: "GP", name: { en: "Guadeloupe", ar: "غوادلوب" } },
  { code: "GU", name: { en: "Guam", ar: "غوام" } },
  { code: "GT", name: { en: "Guatemala", ar: "غواتيمالا" } },
  { code: "GG", name: { en: "Guernsey", ar: "غيرنسي" } },
  { code: "GN", name: { en: "Guinea", ar: "غينيا" } },
  { code: "GW", name: { en: "Guinea-Bissau", ar: "غينيا بيساو" } },
  { code: "GY", name: { en: "Guyana", ar: "غيانا" } },
  { code: "HT", name: { en: "Haiti", ar: "هايتي" } },
  {
    code: "HM",
    name: {
      en: "Heard Island and McDonald Islands",
      ar: "جزيرة هيرد وجزر ماكدونالد",
    },
  },
  { code: "VA", name: { en: "Holy See", ar: "الكرسي الرسولي" } },
  { code: "HN", name: { en: "Honduras", ar: "هندوراس" } },
  { code: "HK", name: { en: "Hong Kong", ar: "هونغ كونغ" } },
  { code: "HU", name: { en: "Hungary", ar: "المجر" } },
  { code: "IS", name: { en: "Iceland", ar: "أيسلندا" } },
  { code: "IN", name: { en: "India", ar: "الهند" } },
  { code: "ID", name: { en: "Indonesia", ar: "إندونيسيا" } },
  { code: "IR", name: { en: "Iran", ar: "إيران" } },
  { code: "IQ", name: { en: "Iraq", ar: "العراق" } },
  { code: "IE", name: { en: "Ireland", ar: "أيرلندا" } },
  { code: "IM", name: { en: "Isle of Man", ar: "جزيرة مان" } },
  { code: "IL", name: { en: "Israel", ar: "إسرائيل" } },
  { code: "IT", name: { en: "Italy", ar: "إيطاليا" } },
  { code: "JM", name: { en: "Jamaica", ar: "جامايكا" } },
  { code: "JP", name: { en: "Japan", ar: "اليابان" } },
  { code: "JE", name: { en: "Jersey", ar: "جيرسي" } },
  { code: "JO", name: { en: "Jordan", ar: "الأردن" } },
  { code: "KZ", name: { en: "Kazakhstan", ar: "كازاخستان" } },
  { code: "KE", name: { en: "Kenya", ar: "كينيا" } },
  { code: "KI", name: { en: "Kiribati", ar: "كيريباتي" } },
  { code: "KP", name: { en: "Korea, North", ar: "كوريا الشمالية" } },
  { code: "KR", name: { en: "Korea, South", ar: "كوريا الجنوبية" } },
  { code: "KW", name: { en: "Kuwait", ar: "الكويت" } },
  { code: "KG", name: { en: "Kyrgyzstan", ar: "قرغيزستان" } },
  { code: "LA", name: { en: "Laos", ar: "لاوس" } },
  { code: "LV", name: { en: "Latvia", ar: "لاتفيا" } },
  { code: "LB", name: { en: "Lebanon", ar: "لبنان" } },
  { code: "LS", name: { en: "Lesotho", ar: "ليسوتو" } },
  { code: "LR", name: { en: "Liberia", ar: "ليبيريا" } },
  { code: "LY", name: { en: "Libya", ar: "ليبيا" } },
  { code: "LI", name: { en: "Liechtenstein", ar: "ليختنشتاين" } },
  { code: "LT", name: { en: "Lithuania", ar: "ليتوانيا" } },
  { code: "LU", name: { en: "Luxembourg", ar: "لوكسمبورغ" } },
  { code: "MO", name: { en: "Macao", ar: "ماكاو" } },
  { code: "MG", name: { en: "Madagascar", ar: "مدغشقر" } },
  { code: "MW", name: { en: "Malawi", ar: "مالاوي" } },
  { code: "MY", name: { en: "Malaysia", ar: "ماليزيا" } },
  { code: "MV", name: { en: "Maldives", ar: "مالديف" } },
  { code: "ML", name: { en: "Mali", ar: "مالي" } },
  { code: "MT", name: { en: "Malta", ar: "مالطا" } },
  { code: "MH", name: { en: "Marshall Islands", ar: "جزر مارشال" } },
  { code: "MQ", name: { en: "Martinique", ar: "مارتينيك" } },
  { code: "MR", name: { en: "Mauritania", ar: "موريتانيا" } },
  { code: "MU", name: { en: "Mauritius", ar: "موريشيوس" } },
  { code: "YT", name: { en: "Mayotte", ar: "مايوت" } },
  { code: "MX", name: { en: "Mexico", ar: "المكسيك" } },
  { code: "FM", name: { en: "Micronesia", ar: "ميكرونيزيا" } },
  { code: "MD", name: { en: "Moldova", ar: "مولدوفا" } },
  { code: "MC", name: { en: "Monaco", ar: "موناكو" } },
  { code: "MN", name: { en: "Mongolia", ar: "منغوليا" } },
  { code: "ME", name: { en: "Montenegro", ar: "الجبل الأسود" } },
  { code: "MS", name: { en: "Montserrat", ar: "مونتسيرات" } },
  { code: "MA", name: { en: "Morocco", ar: "المغرب" } },
  { code: "MZ", name: { en: "Mozambique", ar: "موزمبيق" } },
  { code: "MM", name: { en: "Myanmar", ar: "ميانمار" } },
  { code: "NA", name: { en: "Namibia", ar: "ناميبيا" } },
  { code: "NR", name: { en: "Nauru", ar: "ناورو" } },
  { code: "NP", name: { en: "Nepal", ar: "نيبال" } },
  { code: "NL", name: { en: "Netherlands", ar: "هولندا" } },
  { code: "NC", name: { en: "New Caledonia", ar: "كاليدونيا الجديدة" } },
  { code: "NZ", name: { en: "New Zealand", ar: "نيوزيلندا" } },
  { code: "NI", name: { en: "Nicaragua", ar: "نيكاراغوا" } },
  { code: "NE", name: { en: "Niger", ar: "النيجر" } },
  { code: "NG", name: { en: "Nigeria", ar: "نيجيريا" } },
  { code: "NU", name: { en: "Niue", ar: "نيوي" } },
  { code: "NF", name: { en: "Norfolk Island", ar: "جزيرة نورفولك" } },
  {
    code: "MP",
    name: { en: "Northern Mariana Islands", ar: "جزر ماريانا الشمالية" },
  },
  { code: "NO", name: { en: "Norway", ar: "النرويج" } },
  { code: "OM", name: { en: "Oman", ar: "عمان" } },
  { code: "PK", name: { en: "Pakistan", ar: "باكستان" } },
  { code: "PW", name: { en: "Palau", ar: "بالاو" } },
  { code: "PS", name: { en: "Palestine", ar: "فلسطين" } },
  { code: "PA", name: { en: "Panama", ar: "بنما" } },
  { code: "PG", name: { en: "Papua New Guinea", ar: "بابوا غينيا الجديدة" } },
  { code: "PY", name: { en: "Paraguay", ar: "باراغواي" } },
  { code: "PE", name: { en: "Peru", ar: "بيرو" } },
  { code: "PH", name: { en: "Philippines", ar: "الفلبين" } },
  { code: "PN", name: { en: "Pitcairn", ar: "بيتكيرن" } },
  { code: "PL", name: { en: "Poland", ar: "بولندا" } },
  { code: "PT", name: { en: "Portugal", ar: "البرتغال" } },
  { code: "PR", name: { en: "Puerto Rico", ar: "بورتو ريكو" } },
  { code: "QA", name: { en: "Qatar", ar: "قطر" } },
  { code: "RE", name: { en: "Réunion", ar: "لا ريونيون" } },
  { code: "RO", name: { en: "Romania", ar: "رومانيا" } },
  { code: "RU", name: { en: "Russia", ar: "روسيا" } },
  { code: "RW", name: { en: "Rwanda", ar: "رواندا" } },
  { code: "BL", name: { en: "Saint Barthélemy", ar: "سانت بارثيليمي" } },
  { code: "SA", name: { en: "Saudi Arabia", ar: "السعوديه" } },
  { code: "SH", name: { en: "Saint Helena", ar: "سانت هيلena" } },
  { code: "KN", name: { en: "Saint Kitts and Nevis", ar: "سانت كيتس ونيفيس" } },
  { code: "LC", name: { en: "Saint Lucia", ar: "سانت لوسيا" } },
  { code: "MF", name: { en: "Saint Martin", ar: "سانت مارتن" } },
  { code: "SX", name: { en: "Sint Maarten", ar: "سينت مارتن" } },
  { code: "SB", name: { en: "Solomon Islands", ar: "جزر سليمان" } },
  { code: "SO", name: { en: "Somalia", ar: "الصومال" } },
  { code: "ZA", name: { en: "South Africa", ar: "جنوب إفريقيا" } },
  {
    code: "GS",
    name: {
      en: "South Georgia and the South Sandwich Islands",
      ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    },
  },
  { code: "SS", name: { en: "South Sudan", ar: "جنوب السودان" } },
  { code: "ES", name: { en: "Spain", ar: "إسبانيا" } },
  { code: "LK", name: { en: "Sri Lanka", ar: "سريلانكا" } },
  { code: "SD", name: { en: "Sudan", ar: "السودان" } },
  { code: "SR", name: { en: "Suriname", ar: "سورينام" } },
  {
    code: "SJ",
    name: { en: "Svalbard and Jan Mayen", ar: "سفالبارد وجان ماين" },
  },
  { code: "SE", name: { en: "Sweden", ar: "السويد" } },
  { code: "CH", name: { en: "Switzerland", ar: "سويسرا" } },
  {
    code: "SY",
    name: { en: "Syrian Arab Republic", ar: "الجمهورية العربية السورية" },
  },
  { code: "TW", name: { en: "Taiwan", ar: "تايوان" } },
  { code: "TZ", name: { en: "Tanzania", ar: "تنزانيا" } },
  { code: "TH", name: { en: "Thailand", ar: "تايلند" } },
  { code: "TL", name: { en: "Timor-Leste", ar: "تيمور الشرقية" } },
  { code: "TG", name: { en: "Togo", ar: "توغو" } },
  { code: "TK", name: { en: "Tokelau", ar: "توكلاو" } },
  { code: "TO", name: { en: "Tonga", ar: "تونغا" } },
  { code: "TT", name: { en: "Trinidad and Tobago", ar: "ترينيداد وتوباغو" } },
  { code: "TN", name: { en: "Tunisia", ar: "تونس" } },
  { code: "TR", name: { en: "Turkey", ar: "تركيا" } },
  { code: "TM", name: { en: "Turkmenistan", ar: "تركمانستان" } },
  {
    code: "TC",
    name: { en: "Turks and Caicos Islands", ar: "جزر تركس وكايكوس" },
  },
  { code: "TV", name: { en: "Tuvalu", ar: "توفال" } },
  { code: "UG", name: { en: "Uganda", ar: "أوغندا" } },
  { code: "UA", name: { en: "Ukraine", ar: "أوكرانيا" } },
  {
    code: "AE",
    name: { en: "United Arab Emirates", ar: "الإمارات العربية المتحدة" },
  },
  { code: "GB", name: { en: "United Kingdom", ar: "المملكة المتحدة" } },
  {
    code: "US",
    name: { en: "United States", ar: "الولايات المتحدة الأمريكية" },
  },
  {
    code: "UM",
    name: {
      en: "United States Minor Outlying Islands",
      ar: "الجزر النائية التابعة للولايات المتحدة",
    },
  },
  { code: "UY", name: { en: "Uruguay", ar: "أوروغواي" } },
  { code: "UZ", name: { en: "Uzbekistan", ar: "أوزبكستان" } },
  { code: "VU", name: { en: "Vanuatu", ar: "فانواتو" } },
  { code: "VE", name: { en: "Venezuela", ar: "فنزويلا" } },
  { code: "VN", name: { en: "Vietnam", ar: "فيتنام" } },
  { code: "WF", name: { en: "Wallis and Futuna", ar: "واليس وفوتونا" } },
  { code: "EH", name: { en: "Western Sahara", ar: "الصحراء الغربية" } },
  { code: "YE", name: { en: "Yemen", ar: "اليمن" } },
  { code: "ZM", name: { en: "Zambia", ar: "زامبيا" } },
  { code: "ZW", name: { en: "Zimbabwe", ar: "زيمبابوي" } },
];

export const months = [
  { id: 1, en: "January", ar: "يناير" },
  { id: 2, en: "February", ar: "فبراير" },
  { id: 3, en: "March", ar: "مارس" },
  { id: 4, en: "April", ar: "أبريل" },
  { id: 5, en: "May", ar: "مايو" },
  { id: 6, en: "June", ar: "يونيو" },
  { id: 7, en: "July", ar: "يوليو" },
  { id: 8, en: "August", ar: "أغسطس" },
  { id: 9, en: "September", ar: "سبتمبر" },
  { id: 10, en: "October", ar: "أكتوبر" },
  { id: 11, en: "November", ar: "نوفمبر" },
  { id: 12, en: "December", ar: "ديسمبر" },
];

export const countriesPhones = [
  { en: "Afghanistan", ar: "أفغانستان", code: "AF", phone: "+93" },
  { en: "Albania", ar: "ألبانيا", code: "AL", phone: "+355" },
  { en: "Algeria", ar: "الجزائر", code: "DZ", phone: "+213" },
  { en: "Andorra", ar: "أندورا", code: "AD", phone: "+376" },
  { en: "Angola", ar: "أنغولا", code: "AO", phone: "+244" },
  { en: "Argentina", ar: "الأرجنتين", code: "AR", phone: "+54" },
  { en: "Armenia", ar: "أرمينيا", code: "AM", phone: "+374" },
  { en: "Australia", ar: "أستراليا", code: "AU", phone: "+61" },
  { en: "Austria", ar: "النمسا", code: "AT", phone: "+43" },
  { en: "Azerbaijan", ar: "أذربيجان", code: "AZ", phone: "+994" },
  { en: "Bahrain", ar: "البحرين", code: "BH", phone: "+973" },
  { en: "Bangladesh", ar: "بنغلاديش", code: "BD", phone: "+880" },
  { en: "Belarus", ar: "بيلاروسيا", code: "BY", phone: "+375" },
  { en: "Belgium", ar: "بلجيكا", code: "BE", phone: "+32" },
  { en: "Belize", ar: "بليز", code: "BZ", phone: "+501" },
  { en: "Benin", ar: "بنين", code: "BJ", phone: "+229" },
  { en: "Bhutan", ar: "بوتان", code: "BT", phone: "+975" },
  { en: "Bolivia", ar: "بوليفيا", code: "BO", phone: "+591" },
  {
    en: "Bosnia and Herzegovina",
    ar: "البوسنة والهرسك",
    code: "BA",
    phone: "+387",
  },
  { en: "Botswana", ar: "بوتسوانا", code: "BW", phone: "+267" },
  { en: "Brazil", ar: "البرازيل", code: "BR", phone: "+55" },
  { en: "Brunei", ar: "بروناي", code: "BN", phone: "+673" },
  { en: "Bulgaria", ar: "بلغاريا", code: "BG", phone: "+359" },
  { en: "Burkina Faso", ar: "بوركينا فاسو", code: "BF", phone: "+226" },
  { en: "Burundi", ar: "بوروندي", code: "BI", phone: "+257" },
  { en: "Cambodia", ar: "كمبوديا", code: "KH", phone: "+855" },
  { en: "Cameroon", ar: "الكاميرون", code: "CM", phone: "+237" },
  { en: "Canada", ar: "كندا", code: "CA", phone: "+1" },
  { en: "Cape Verde", ar: "الرأس الأخضر", code: "CV", phone: "+238" },
  {
    en: "Central African Republic",
    ar: "جمهورية أفريقيا الوسطى",
    code: "CF",
    phone: "+236",
  },
  { en: "Chad", ar: "تشاد", code: "TD", phone: "+235" },
  { en: "Chile", ar: "تشيلي", code: "CL", phone: "+56" },
  { en: "China", ar: "الصين", code: "CN", phone: "+86" },
  { en: "Colombia", ar: "كولومبيا", code: "CO", phone: "+57" },
  { en: "Comoros", ar: "جزر القمر", code: "KM", phone: "+269" },
  { en: "Congo", ar: "الكونغو", code: "CG", phone: "+242" },
  { en: "Costa Rica", ar: "كوستاريكا", code: "CR", phone: "+506" },
  { en: "Croatia", ar: "كرواتيا", code: "HR", phone: "+385" },
  { en: "Cuba", ar: "كوبا", code: "CU", phone: "+53" },
  { en: "Cyprus", ar: "قبرص", code: "CY", phone: "+357" },
  { en: "Czech Republic", ar: "التشيك", code: "CZ", phone: "+420" },
  { en: "Denmark", ar: "الدنمارك", code: "DK", phone: "+45" },
  { en: "Djibouti", ar: "جيبوتي", code: "DJ", phone: "+253" },
  { en: "Dominica", ar: "دومينيكا", code: "DM", phone: "+1-767" },
  {
    en: "Dominican Republic",
    ar: "جمهورية الدومينيكان",
    code: "DO",
    phone: "+1-809",
  },
  { en: "Ecuador", ar: "الإكوادور", code: "EC", phone: "+593" },
  { en: "Egypt", ar: "مصر", code: "EG", phone: "+20" },
  { en: "El Salvador", ar: "السلفادور", code: "SV", phone: "+503" },
  {
    en: "Equatorial Guinea",
    ar: "غينيا الاستوائية",
    code: "GQ",
    phone: "+240",
  },
  { en: "Eritrea", ar: "إريتريا", code: "ER", phone: "+291" },
  { en: "Estonia", ar: "إستونيا", code: "EE", phone: "+372" },
  { en: "Eswatini", ar: "إسواتيني", code: "SZ", phone: "+268" },
  { en: "Ethiopia", ar: "إثيوبيا", code: "ET", phone: "+251" },
  { en: "Fiji", ar: "فيجي", code: "FJ", phone: "+679" },
  { en: "Finland", ar: "فنلندا", code: "FI", phone: "+358" },
  { en: "France", ar: "فرنسا", code: "FR", phone: "+33" },
  { en: "Gabon", ar: "الغابون", code: "GA", phone: "+241" },
  { en: "Gambia", ar: "غامبيا", code: "GM", phone: "+220" },
  { en: "Georgia", ar: "جورجيا", code: "GE", phone: "+995" },
  { en: "Germany", ar: "ألمانيا", code: "DE", phone: "+49" },
  { en: "Ghana", ar: "غانا", code: "GH", phone: "+233" },
  { en: "Greece", ar: "اليونان", code: "GR", phone: "+30" },
  { en: "Grenada", ar: "غرينادا", code: "GD", phone: "+1-473" },
  { en: "Guatemala", ar: "غواتيمالا", code: "GT", phone: "+502" },
  { en: "Guinea", ar: "غينيا", code: "GN", phone: "+224" },
  { en: "Guyana", ar: "غيانا", code: "GY", phone: "+592" },
  { en: "Haiti", ar: "هايتي", code: "HT", phone: "+509" },
  { en: "Honduras", ar: "هندوراس", code: "HN", phone: "+504" },
  { en: "Hungary", ar: "المجر", code: "HU", phone: "+36" },
  { en: "Iceland", ar: "أيسلندا", code: "IS", phone: "+354" },
  { en: "India", ar: "الهند", code: "IN", phone: "+91" },
  { en: "Indonesia", ar: "إندونيسيا", code: "ID", phone: "+62" },
  { en: "Iran", ar: "إيران", code: "IR", phone: "+98" },
  { en: "Iraq", ar: "العراق", code: "IQ", phone: "+964" },
  { en: "Ireland", ar: "أيرلندا", code: "IE", phone: "+353" },
  { en: "Israel", ar: "إسرائيل", code: "IL", phone: "+972" },
  { en: "Italy", ar: "إيطاليا", code: "IT", phone: "+39" },
  { en: "Jamaica", ar: "جامايكا", code: "JM", phone: "+1-876" },
  { en: "Japan", ar: "اليابان", code: "JP", phone: "+81" },
  { en: "Jordan", ar: "الأردن", code: "JO", phone: "+962" },
  { en: "Kazakhstan", ar: "كازاخستان", code: "KZ", phone: "+7" },
  { en: "Kenya", ar: "كينيا", code: "KE", phone: "+254" },
  { en: "Kuwait", ar: "الكويت", code: "KW", phone: "+965" },
  { en: "Kyrgyzstan", ar: "قرغيزستان", code: "KG", phone: "+996" },
  { en: "Laos", ar: "لاوس", code: "LA", phone: "+856" },
  { en: "Latvia", ar: "لاتفيا", code: "LV", phone: "+371" },
  { en: "Lebanon", ar: "لبنان", code: "LB", phone: "+961" },
  { en: "Lesotho", ar: "ليسوتو", code: "LS", phone: "+266" },
  { en: "Liberia", ar: "ليبيريا", code: "LR", phone: "+231" },
  { en: "Libya", ar: "ليبيا", code: "LY", phone: "+218" },
  { en: "Liechtenstein", ar: "ليختنشتاين", code: "LI", phone: "+423" },
  { en: "Lithuania", ar: "ليتوانيا", code: "LT", phone: "+370" },
  { en: "Luxembourg", ar: "لوكسمبورغ", code: "LU", phone: "+352" },
  { en: "Madagascar", ar: "مدغشقر", code: "MG", phone: "+261" },
  { en: "Malawi", ar: "مالاوي", code: "MW", phone: "+265" },
  { en: "Malaysia", ar: "ماليزيا", code: "MY", phone: "+60" },
  { en: "Maldives", ar: "المالديف", code: "MV", phone: "+960" },
  { en: "Mali", ar: "مالي", code: "ML", phone: "+223" },
  { en: "Malta", ar: "مالطا", code: "MT", phone: "+356" },
  { en: "Marshall Islands", ar: "جزر مارشال", code: "MH", phone: "+692" },
  { en: "Mauritania", ar: "موريتانيا", code: "MR", phone: "+222" },
  { en: "Mauritius", ar: "موريشيوس", code: "MU", phone: "+230" },
  { en: "Mexico", ar: "المكسيك", code: "MX", phone: "+52" },
  { en: "Micronesia", ar: "ميكرونيزيا", code: "FM", phone: "+691" },
  { en: "Moldova", ar: "مولدوفا", code: "MD", phone: "+373" },
  { en: "Monaco", ar: "موناكو", code: "MC", phone: "+377" },
  { en: "Mongolia", ar: "منغوليا", code: "MN", phone: "+976" },
  { en: "Montenegro", ar: "الجبل الأسود", code: "ME", phone: "+382" },
  { en: "Morocco", ar: "المغرب", code: "MA", phone: "+212" },
  { en: "Mozambique", ar: "موزمبيق", code: "MZ", phone: "+258" },
  { en: "Myanmar", ar: "ميانمار", code: "MM", phone: "+95" },
  { en: "Namibia", ar: "ناميبيا", code: "NA", phone: "+264" },
  { en: "Nauru", ar: "ناورو", code: "NR", phone: "+674" },
  { en: "Nepal", ar: "نيبال", code: "NP", phone: "+977" },
  { en: "Netherlands", ar: "هولندا", code: "NL", phone: "+31" },
  { en: "New Zealand", ar: "نيوزيلندا", code: "NZ", phone: "+64" },
  { en: "Nicaragua", ar: "نيكاراغوا", code: "NI", phone: "+505" },
  { en: "Niger", ar: "النيجر", code: "NE", phone: "+227" },
  { en: "Nigeria", ar: "نيجيريا", code: "NG", phone: "+234" },
  { en: "North Macedonia", ar: "مقدونيا الشمالية", code: "MK", phone: "+389" },
  { en: "Norway", ar: "النرويج", code: "NO", phone: "+47" },
  { en: "Oman", ar: "عمان", code: "OM", phone: "+968" },
  { en: "Pakistan", ar: "باكستان", code: "PK", phone: "+92" },
  { en: "Palau", ar: "بالاو", code: "PW", phone: "+680" },
  { en: "Panama", ar: "بنما", code: "PA", phone: "+507" },
  {
    en: "Papua New Guinea",
    ar: "بابوا غينيا الجديدة",
    code: "PG",
    phone: "+675",
  },
  { en: "Paraguay", ar: "باراغواي", code: "PY", phone: "+595" },
  { en: "Peru", ar: "بيرو", code: "PE", phone: "+51" },
  { en: "Philippines", ar: "الفلبين", code: "PH", phone: "+63" },
  { en: "Poland", ar: "بولندا", code: "PL", phone: "+48" },
  { en: "Portugal", ar: "البرتغال", code: "PT", phone: "+351" },
  { en: "Qatar", ar: "قطر", code: "QA", phone: "+974" },
  { en: "Romania", ar: "رومانيا", code: "RO", phone: "+40" },
  { en: "Russia", ar: "روسيا", code: "RU", phone: "+7" },
  { en: "Rwanda", ar: "رواندا", code: "RW", phone: "+250" },
  {
    en: "Saint Kitts and Nevis",
    ar: "سانت كيتس ونيفيس",
    code: "KN",
    phone: "+1-869",
  },
  { en: "Saint Lucia", ar: "سانت لوسيا", code: "LC", phone: "+1-758" },
  {
    en: "Saint Vincent and the Grenadines",
    ar: "سانت فنسنت وجزر غرينادين",
    code: "VC",
    phone: "+1-784",
  },
  { en: "Samoa", ar: "ساموا", code: "WS", phone: "+685" },
  { en: "San Marino", ar: "سان مارينو", code: "SM", phone: "+378" },
  { en: "Saudi Arabia", ar: "السعودية", code: "SA", phone: "+966" },
  { en: "Senegal", ar: "السنغال", code: "SN", phone: "+221" },
  { en: "Serbia", ar: "صربيا", code: "RS", phone: "+381" },
  { en: "Seychelles", ar: "سيشل", code: "SC", phone: "+248" },
  { en: "Sierra Leone", ar: "سيراليون", code: "SL", phone: "+232" },
  { en: "Singapore", ar: "سنغافورة", code: "SG", phone: "+65" },
  { en: "Slovakia", ar: "سلوفاكيا", code: "SK", phone: "+421" },
  { en: "Slovenia", ar: "سلوفينيا", code: "SI", phone: "+386" },
  { en: "Solomon Islands", ar: "جزر سليمان", code: "SB", phone: "+677" },
  { en: "Somalia", ar: "الصومال", code: "SO", phone: "+252" },
  { en: "South Africa", ar: "جنوب أفريقيا", code: "ZA", phone: "+27" },
  { en: "South Korea", ar: "كوريا الجنوبية", code: "KR", phone: "+82" },
  { en: "Spain", ar: "إسبانيا", code: "ES", phone: "+34" },
  { en: "Sri Lanka", ar: "سريلانكا", code: "LK", phone: "+94" },
  { en: "Sudan", ar: "السودان", code: "SD", phone: "+249" },
  { en: "Surien", ar: "سورينام", code: "SR", phone: "+597" },
  { en: "Sweden", ar: "السويد", code: "SE", phone: "+46" },
  { en: "Switzerland", ar: "سويسرا", code: "CH", phone: "+41" },
  { en: "Syria", ar: "سوريا", code: "SY", phone: "+963" },
  { en: "Taiwan", ar: "تايوان", code: "TW", phone: "+886" },
  { en: "Tajikistan", ar: "طاجيكستان", code: "TJ", phone: "+992" },
  { en: "Tanzania", ar: "تنزانيا", code: "TZ", phone: "+255" },
  { en: "Thailand", ar: "تايلاند", code: "TH", phone: "+66" },
  { en: "Togo", ar: "توغو", code: "TG", phone: "+228" },
  { en: "Tonga", ar: "تونغا", code: "TO", phone: "+676" },
  {
    en: "Trinidad and Tobago",
    ar: "ترينيداد وتوباغو",
    code: "TT",
    phone: "+1-868",
  },
  { en: "Tunisia", ar: "تونس", code: "TN", phone: "+216" },
  { en: "Turkey", ar: "تركيا", code: "TR", phone: "+90" },
  { en: "Turkmenistan", ar: "تركمانستان", code: "TM", phone: "+993" },
  { en: "Tuvalu", ar: "توفالو", code: "TV", phone: "+688" },
  { en: "Uganda", ar: "أوغندا", code: "UG", phone: "+256" },
  { en: "Ukraine", ar: "أوكرانيا", code: "UA", phone: "+380" },
  { en: "United Arab Emirates", ar: "الإمارات", code: "AE", phone: "+971" },
  { en: "United Kingdom", ar: "المملكة المتحدة", code: "GB", phone: "+44" },
  { en: "United States", ar: "الولايات المتحدة", code: "US", phone: "+1" },
  { en: "Uruguay", ar: "أوروغواي", code: "UY", phone: "+598" },
  { en: "Uzbekistan", ar: "أوزبكستان", code: "UZ", phone: "+998" },
  { en: "Vanuatu", ar: "فانواتو", code: "VU", phone: "+678" },
  { en: "Vatican City", ar: "الفاتيكان", code: "VA", phone: "+379" },
  { en: "Venezuela", ar: "فنزويلا", code: "VE", phone: "+58" },
  { en: "Vietnam", ar: "فيتنام", code: "VN", phone: "+84" },
  { en: "Yemen", ar: "اليمن", code: "YE", phone: "+967" },
  { en: "Zambia", ar: "زامبيا", code: "ZM", phone: "+260" },
  { en: "Zimbabwe", ar: "زيمبابوي", code: "ZW", phone: "+263" },
];
