import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCityById } from "../../services/apiPlanes";

export default function useLocation(code) {
  const [cityName, setCity] = useState("");
  const [airport, setAirport] = useState("");
  const { i18n, t } = useTranslation();
  const lan = i18n.language === "ar" ? "ar-AE" : "en-US";
  useEffect(() => {
    async function getCity() {
      try {
        const locationData = await getCityById(code, lan);
        console.log(locationData);
        setCity(locationData.city.name);
        setAirport(locationData?.name);
      } catch (err) {
        setCity("");
      }
    }
    getCity();
  }, [code, lan]);
  const memoizedCityName = useMemo(() => cityName, [cityName]);

  return [memoizedCityName, airport];
}
