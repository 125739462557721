import { createContext, useContext } from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";

const SreachContext = createContext();

export default function SearchFlightsProvider({ children }) {
  const [origin, onOrigin, onRemoveOrigin] = useLocalStorageState(
    null,
    "origin"
  );
  const [destination, onDestination, onRemoveDestination] =
    useLocalStorageState(null, "destination");

  const [outboundDate, setOutboundDate, onRemoveOutboundDate] =
    useLocalStorageState(null, "outboundDate");
  const [inboundDate, setInboundDate, onRemoveInboundDate] =
    useLocalStorageState(null, "inboundDate");
  const [cabinClass, setCabinClass] = useLocalStorageState("M", "cabinClass");

  const removeDate = () => {
    onRemoveOutboundDate();
    onRemoveInboundDate();
  };

  return (
    <SreachContext.Provider
      value={{
        origin,
        onOrigin,
        onRemoveOrigin,
        destination,
        onDestination,
        onRemoveDestination,
        outboundDate,
        setOutboundDate,
        inboundDate,
        setInboundDate,
        cabinClass,
        setCabinClass,
        removeDate,
        onRemoveInboundDate,
      }}
    >
      {children}
    </SreachContext.Provider>
  );
}

export const useSearchFlights = () => {
  const context = useContext(SreachContext);
  if (context === undefined)
    throw new Error("SearchFlights was used outside of SearchFlightsProvider");
  return context;
};
