import React, { useEffect, useRef, useState } from "react";

const PaymentForm = () => {
  const [error, setError] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    // Load the PayTabs library
    const script = document.createElement("script");
    script.src = "https://secure.paytabs.sa/payment/js/paylib.js";
    script.async = true;
    script.onload = () => {
      // Define paylib after the script loads
      const paylib = window.paylib; // Access paylib from the global window object

      // Initialize PayTabs when the script is loaded
      paylib.inlineForm({
        key: "CTKMRG-KKMT66-9TVGNH-NKHK7T", // Replace with your actual client key
        form: formRef.current,
        autoSubmit: true,
        callback: (response) => {
          setError(""); // Clear previous errors
          if (response.error) {
            handleError(response);
          }
        },
      });
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleError = (response) => {
    setError(response.error.message || "An unknown error occurred.");
  };

  return (
    <div className="h-screen mt-[100px]" dir="ltr">
      <form action="https://yourserver.com/payment" ref={formRef} method="post">
        {error && <span id="paymentErrors">{error}</span>}
        <div className="row">
          <label>Card Number</label>
          <input
            type="text"
            data-paylib="number"
            size="20"
            required
            className=" bg-gray-300"
          />
        </div>
        <div className="row">
          <label>Expiry Date (MM/YYYY)</label>
          <input
            type="text"
            data-paylib="expmonth"
            size="2"
            required
            className=" bg-gray-300"
          />
          <input
            type="text"
            data-paylib="expyear"
            size="4"
            required
            className=" bg-gray-300"
          />
        </div>
        <div className="row">
          <label>Security Code</label>
          <input
            type="text"
            data-paylib="cvv"
            size="4"
            required
            className=" bg-gray-300"
          />
        </div>
        <input type="submit" value="Place order" />
      </form>
    </div>
  );
};

export default PaymentForm;
