import React, { useEffect, useMemo, useState } from "react";
import SearchLocation from "./SearchLocation";
import { useTranslation } from "react-i18next";
import { getCityById } from "../../../../services/apiPlanes";
import useLocation from "../../../search/useLocation";

function LocationInput({ code, title, icon, placeholder, onLocation }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <Location
        onActive={setIsActive}
        code={code}
        title={title}
        icon={icon}
        placeholder={placeholder}
      />
      <SearchLocation
        isActive={isActive}
        onActive={setIsActive}
        onLocation={onLocation}
      />
    </div>
  );
}

export default LocationInput;

const Location = ({ icon, title, placeholder, onActive, code }) => {
  const [cityName] = useLocation(code);
  const { i18n, t } = useTranslation();

  return (
    <div
      className="flex gap-4 items-center border rounded-md py-2  px-3  bg-white"
      onClick={() => onActive?.(true)}
    >
      {icon}
      <div>
        <p className="text-sm text-gray-600 font-semibold">{t(title)}</p>
        {code ? (
          <p className="text-sm text-gray-800 font-semibold">
            {cityName} {code ? `(${code})` : null}
          </p>
        ) : (
          <p className="text-sm text-gray-400 font-semibold">
            {t(placeholder)}
          </p>
        )}
      </div>
    </div>
  );
};
