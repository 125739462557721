import React, { useState } from "react";
import { useSearchType } from "../../../context/searchTypeContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchFlights } from "../../../context/SearchFlightsContext";
import { v4 as uuidv4 } from "uuid";

export default function useSearch() {
  let [searchParams, setSearchParams] = useSearchParams();
  const newId = uuidv4();
  const navigate = useNavigate();
  const {
    origin,
    destination,
    outboundDate,
    cabinClass,
    inboundDate,
    onRemoveInboundDate,
  } = useSearchFlights();
  const { searchType } = useSearchType();
  const handleSearch = (e) => {
    e && e.preventDefault();
    const params = new URLSearchParams(searchParams);
    params.set("origin", origin);
    params.set("destination", destination);
    params.set("outboundDate", outboundDate);
    params.set("cabinClass", cabinClass);
    params.set("type", searchType);
    params.set("sessionId", newId);
    if (searchType === "return") {
      params.set("inboundDate", inboundDate);
    } else {
      params.delete("inboundDate");
      onRemoveInboundDate();
    }

    setSearchParams(params); // Set the updated search params
    navigate(`/search?${params.toString()}`); // Navigate to the new URL
  };

  return [handleSearch];
}
