import React, { useRef, useState } from "react";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { locationSearch } from "../../../../services/apiPlanes";
import { useTranslation } from "react-i18next";

export default function SearchLocation({ onLocation, onActive, isActive }) {
  return (
    <div
      className={`w-full fixed h-full bg-white left-0 ${
        isActive ? "top-0" : "top-[100vh]"
      } duration-100 transition-all  z-50 px-3`}
    >
      <Header onActive={onActive} />
      <SearchBar onLocation={onLocation} onActive={onActive} />
    </div>
  );
}

const Header = ({ onActive, title }) => {
  return (
    <div className="flex items-center justify-between pt-5">
      <IoMdClose className="text-2xl" onClick={() => onActive?.(false)} />
      <p className="text-xl font-semibold text-center w-full">{title}</p>
    </div>
  );
};

const SearchBar = ({ onLocation, onActive }) => {
  const [locations, setLocations] = useState([]);
  const { i18n, t } = useTranslation();
  const lan = i18n.language === "ar" ? "ar-AE" : "en-US";
  const refInput = useRef(null);
  const handleSearchLocation = async (term) => {
    if (term) {
      try {
        const locations = await locationSearch(term, lan);
        setLocations(locations);
      } catch (err) {
        setLocations([]);
      }
    } else {
      setLocations([]);
    }
    return null;
  };

  const handleLocation = (code) => {
    onLocation(code);
    onActive(false);
  };
  console.log(locations);
  return (
    <div>
      <div className="bg-gray-200 w-full py-2 flex items-center px-3 text-balck rounded-md gap-3 mt-5">
        <IoIosSearch className="text-2xl" />
        <input
          type="text"
          ref={refInput}
          className="w-full h-full  bg-transparent outline-none flex items-center placeholder:text-gray-600 text-black"
          placeholder="بحث...."
          onChange={(e) => handleSearchLocation(e.target.value)}
        />
      </div>
      <div className="mt-5 flex flex-col gap-3 overflow-y-auto h-screen">
        {refInput.current?.value && locations.length > 0 && (
          <Locations locations={locations} onLocation={handleLocation} />
        )}
      </div>
    </div>
  );
};

const Locations = ({ locations, onLocation }) => {
  return (
    <div className="mt-5 flex flex-col gap-5 overflow-y-auto h-screen">
      {locations?.map((location) => (
        <div
          key={location.id}
          className="py-2 hover:bg-gray-200 px-2 rounded-lg flex items-center justify-between  cursor-pointer"
          onClick={() => onLocation(location.code)}
        >
          <p className="text-gray-900">
            {location.name}, <span>{location.country.name}</span>
          </p>
          {location?.code && (
            <span className="bg-blue-600 text-white py-1 px-1 rounded-md text-sm">
              {location.code}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
