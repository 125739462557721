import React from "react";
import TicketCard from "./TicketCard";
import { useTranslation } from "react-i18next";

export default function FlightsDetails({ flights }) {
  const { t } = useTranslation();
  return (
    <div className="bg-white  py-5 w-full border rounded-md">
      <p className="text-lg font-semibold border-b px-5 pb-5">
        {t("tripOverview")}
      </p>
      <div className="px-5  mt-5">
        <div className="flex flex-col gap-5">
          {flights?.flights.map((flight) => (
            <TicketCard
              origin={flight?.src}
              destination={flight?.dst}
              arrival={flight?.utc_arrival}
              departure={flight?.utc_departure}
              flight={flight}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
