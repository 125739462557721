import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";

export default function Price({ flights }) {
  const { t } = useTranslation();
  return (
    <>
      <PriceMobile flights={flights} />
      <div className="w-fit h-fit rounded-md hidden xl:block">
        {/* <div className="flex items-center gap-1 font-semibold text-lg">
          <p className="text-xl">{flights?.conversion?.adults_price}</p>
          <p>({flights?.conversion?.currency})</p>
        </div> */}
        {/* <IoIosArrowDown className="text-xl" /> */}
        <div className=" xl:bg-white bg-blue-800 h-fit py-5 rounded-md border bottom-0   left-0 right-0">
          <p className="text-base font-semibold border-b px-5 pb-5">
            {t("priceDetails")}
          </p>
          <div className="">
            <div className="px-5 pt-5 flex-col flex gap-5 ">
              <Passengers flights={flights} t={t} />
              <Total flights={flights} />
              <p className=" text-sm text-gray-600">{t("includes")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Passengers = ({ flights }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between xl:text-gray-800">
      <div className="flex items-center gap-2">
        <p className="font-semibold text-sm">{flights?.pnum}x</p>
        <p className=" font-medium">{t("passenger")}</p>
      </div>
      <div className="flex items-center gap-1 font-semibold text-sm">
        <p>{flights?.conversion?.adults_price}</p>
        <p>{flights?.conversion?.currency}</p>
      </div>
    </div>
  );
};

const Total = ({ flights }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between ">
      <p className=" font-semibold">
        {t("total")} ({flights?.conversion?.currency})
      </p>
      <div className="flex items-center gap-1 font-semibold text-lg">
        <p>{flights?.conversion?.adults_price}</p>
        <p>{flights?.conversion?.currency}</p>
      </div>
    </div>
  );
};

const PriceMobile = ({ flights }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  return (
    <div className=" w-fit h-fit py-3 px-5 text-white rounded-md  bottom-2  fixed bg-blue-800  left-2 right-2 xl:hidden ">
      <div
        className={`${
          isActive ? "hidden" : "flex"
        }  items-center gap-2 z-10 duration-100 transition-shadow`}
        onClick={() => setIsActive(true)}
      >
        <div className="flex items-center gap-1 font-semibold text-base">
          <p className="text-lg">{flights?.conversion?.adults_price}</p>
          <p className="text-sm">({flights?.conversion?.currency})</p>
        </div>
        <IoIosArrowDown className="text-lg" />
      </div>

      <div
        className={`h-fit rounded-md text-white relative ${
          isActive ? "flex" : "hidden"
        }`}
      >
        <div
          className=" absolute bg-blue-700 left-1/2 right-1/2 translate-x-1/2 top-[-18%] w-8 h-8 flex items-center justify-center rounded-full"
          onClick={() => setIsActive(false)}
        >
          <IoIosArrowDown className="text-xl" />
        </div>
        <div className="px-5 pt-5 flex-col flex gap-5 ">
          <Passengers flights={flights} />
          <Total flights={flights} />
          <p className=" text-sm ">{t("includes")} </p>
        </div>
      </div>
    </div>
  );
};
