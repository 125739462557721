import React from "react";
import Input from "../../ui/Input";
import PhoneInput from "../../ui/PhoneInput";

export default function Payment() {
  return (
    <div>
      <Contact />

      <button
        className="flex items-center bg-blue-700 text-white px-5 rounded-md h-[45px] justify-center xl:w-auto w-full mt-5"
        type="submit"
      >
        التوجه للدفع
      </button>
    </div>
  );
}

const Contact = () => {
  return (
    <div className="pt-5 bg-white border rounded-md mb-5">
      <div className="text-lg font-semibold border-b  pb-5 flex items-center justify-between">
        <p className="px-5">تفاصيل التواصل</p>
      </div>
      <div className="flex items-center justify-between w-full gap-5 py-5 px-5">
        <Input name={"email"} label={"البريد الاكتروني"} />
        <PhoneInput name={"phone"} label={"رقم الجوال"} />
      </div>
    </div>
  );
};
