import React from "react";
import { Link } from "react-router-dom";

export default function PaymentFailure() {
  return (
    <div className="flex flex-col items-center justify-start mt-10 h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <div className="mb-6">
          {/* Failure Icon */}
          <svg
            className="w-16 h-16 text-red-500 mx-auto mb-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.707a1 1 0 00-1.414-1.414L10 8.586 7.707 6.293a1 1 0 10-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 101.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414L11.414 10l2.293-2.293a1 1 0 000-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-gray-800 mb-5">فشل الدفع!</h2>
        <p className="text-gray-600 mb-8 font-semibold">
          نأسف، حدث خطأ أثناء معالجة الدفع الخاص بك. يرجى المحاولة مرة أخرى.
        </p>
        <div className="space-x-4">
          {/* <Link
            className="bg-red-600 hover:bg-red-700 text-white px-6 py-4 rounded-lg"
            to={"/retry-payment"}
          >
            حاول مرة اخرى
          </Link> */}
          <Link
            className="bg-blue-700 hover:bg-blue-800 text-white px-6 py-4 rounded-lg"
            to={"/"}
          >
            الصفحة الرئيسيه
          </Link>
        </div>
      </div>
    </div>
  );
}
