import axios from "axios";

export const checkFlights = async (body) => {
  try {
    const response = await axios.get(
      "https://api.tequila.kiwi.com/v2/booking/check_flights",
      {
        params: {
          ...body,
          currency: "SAR",
        },
        headers: {
          accept: "application/json",
          apikey: "yTrA8Iq0rpFUzY3TgRRBOXFnljTlt2aM",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
