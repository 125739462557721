import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
const client = createClient({
  projectId: "vyxv7r0t",
  dataset: "production",
  apiVersion: "2021-09-29",
  useCdn: true,
  ignoreBrowserTokenWarning: true,
  token:
    "skBx4hY5I1qjNAhzXQWPudtyl2SSqZFS3Mzn1CgHsVRzrnVCfXY8Cc5WXiJiccui3QR7JId9kHUsEdchr2e7xAxqm4pPkSem8Ne6pO8B6iPqs7vO8udKxLCAyFaE3z22cVM3sqZX3COJbZ87RYHuo8SjsFnvO3EMrinu3qNggnGGHIzhPQ79",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

export default client;
