import { createContext, useContext } from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";

const Context = createContext();

export const SearchTypeContext = ({ children }) => {
  const [searchType, setSearchType] = useLocalStorageState(
    "no-return",
    "searchType"
  );
  return (
    <Context.Provider value={{ searchType, setSearchType }}>
      {children}
    </Context.Provider>
  );
};

export const useSearchType = () => {
  const context = useContext(Context);
  if (context === undefined)
    throw new Error("DarkModeContext was used outside of DarkModeProvider");
  return context;
};
