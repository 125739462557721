import { useState, useEffect } from "react";

export function useLocalStorageState(initialState, key) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? storedValue : initialState;
  });

  useEffect(() => {
    if (value !== null && value !== "") {
      localStorage.setItem(key, value); // Store as a simple string without JSON.stringify
      setValue(value);
    }
  }, [value, key]);

  // Remove item from localStorage and set state to null
  const onRemove = () => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return [value, setValue, onRemove];
}
