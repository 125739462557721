import React, { useState } from "react";
import styled from "styled-components";
import Select from "./Select";
const StyledFormRow = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  position: relative;
  padding: 1.2rem 0;
  /* 
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  } */

  /* &:not(:last-child) {
    border-bottom: 1px solid #00000035;
  } */

  &:has(button) {
    display: flex;
    justify-content: flex-end;
    gap: 1.2rem;
  }
`;
// Styled select components
const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 15px;
`;
const Error = styled.span`
  font-size: 1rem;
  color: #b91c1c;
  position: absolute;
  bottom: -10px;
`;
const BirthDateSelect = ({ label, register, errors, id, t }) => {
  // State for day, month, year
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  // Generate day, month, and year options
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years =
    id === "passportOrIdExpiryDate"
      ? Array.from({ length: 11 }, (_, i) => currentYear + i)
      : Array.from({ length: 100 }, (_, i) => currentYear - i);
  const handleSubmit = (e) => {
    e.preventDefault();
    const birthDate = `${year}-${month}-${day}`;
    console.log("Selected Birth Date:", birthDate);
  };

  return (
    <StyledFormRow className="w-full">
      <Label>{label}</Label>
      <SelectWrapper>
        {/* Day select */}
        <Select
          onChange={(e) => setDay(e.target.value)}
          {...register(`${id}-day`, {
            required: t(`customerForm.${id}.required`),
          })}
          err={errors[`${id}-day`] ? false : true}
        >
          <option value="" className="text-gray-600" disabled selected>
            {t(`customerForm.date.day`)}
          </option>
          {days.map((d) => (
            <option key={d} value={d}>
              {d}
            </option>
          ))}
        </Select>

        {/* Month select */}
        <Select
          onChange={(e) => setMonth(e.target.value)}
          {...register(`${id}-month`, {
            required: t(`customerForm.${id}.required`),
          })}
          err={errors[`${id}-month`] ? false : true}
        >
          <option value="" disabled selected>
            {" "}
            {t(`customerForm.date.month`)}
          </option>
          {months.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </Select>

        <Select
          onChange={(e) => setYear(e.target.value)}
          {...register(`${id}-year`, {
            required: t(`customerForm.${id}.required`),
          })}
          err={errors[`${id}-year`] ? false : true}
        >
          <option value="" disabled selected>
            {t(`customerForm.date.year`)}
          </option>
          {years.map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </Select>
        {errors && (
          <Error>
            {errors[`${id}-year`]?.message ||
              errors[`${id}-month`]?.message ||
              errors[`${id}-day`]?.message}
          </Error>
        )}
      </SelectWrapper>
    </StyledFormRow>
  );
};

export default BirthDateSelect;
