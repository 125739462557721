import styled from "styled-components";
const SelectWrapper = styled.div`
  width: 100%;
`;
const Select = styled.select`
  border: 1px solid ${(props) => (props.err ? "#d1d5db" : "#b91c1c")};
  background-color: white;
  border-radius: 7px;
  padding: 0.6rem 1.2rem;
  box-shadow: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06);
  width: 100%;
  color: ${(props) =>
    props.defaultValue === ""
      ? "#888"
      : "#333"}; /* Placeholder color when no value is selected */
  appearance: none; /* Removes default dropdown arrow */

  &:focus {
    outline: 2px solid ${(props) => (props.err ? "#4f46e5" : "#b91c1c")};
    outline-offset: -1px;
  }
  option {
    color: #333; /* Regular option color */
  }

  /* Placeholder styling */
  option[disabled] {
    color: #888; /* Light grey color for the placeholder */
    font-weight: bold;
  }

  &:disabled {
    color: #8888; /* Light grey color for the placeholder */
    font-weight: bold;
  }
`;
export default Select;
