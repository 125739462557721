import React from "react";
import TypePlane from "../../ui/TypePlane";
import SearchForm from "../../features/home/mobile/flight/SearchForm";
import useSearch from "../../features/home/flight/useSearch";
import SearchButton from "../../features/home/flight/SearchButton";

export default function Fligths() {
  const [handleSearch] = useSearch();

  return (
    <div className="w-full h-screen mt-14 pt-10 flex flex-col items-center bg-gray-200 px-2">
      <TypePlane />
      <SearchForm />
      <SearchButton onSearch={handleSearch} />
    </div>
  );
}
