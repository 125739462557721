import React, { useEffect, useState } from "react";
import { checkFlights } from "../services/apiBooking";
import { useSearchParams } from "react-router-dom";
import Price from "../features/booking/Price";
import Stepper from "../ui/Stepper";
import Spinner from "../ui/Spinner";
import CustomertInfo from "../features/booking/CustomertInfo";
import Payment from "../features/booking/Payment";
import FlightsDetails from "../features/booking/FlightsDetails";
import CustomerForm from "../features/booking/CustomerForm";

export default function BookingPayment() {
  let [searchParams] = useSearchParams();
  const [flights, setFlights] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const booking_token = searchParams.get("token");
  const session_id = searchParams.get("sessionId");
  const step = searchParams.get("activeStep");

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const res = await checkFlights({
        booking_token,
        bnum: "2",
        adults: "1",
        children: "0",
        infants: "0",
        session_id,
      });
      setFlights(res);
      setIsLoading(false);
    }
    getData();
  }, [booking_token, session_id]);
  if (isLoading) return <Spinner />;
  console.log(flights);
  return (
    <div className="grid xl:grid-cols-[1fr,0.4fr]  gap-10 relative">
      <div className="flex flex-col gap-5">
        <FlightsDetails flights={flights} />
        {step == 1 ? <CustomerForm flights={flights} /> : <Payment />}
      </div>
      <Price flights={flights} />
    </div>
  );
}
