import React from "react";
import { logo } from "../assets/image";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const Logo = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Link to={"/"}>
      <div className=" h-[32px] md:h-[35px]">
        <img
          src={logo}
          alt="flymoon logo"
          className="w-full h-full object-contain"
        />
      </div>
    </Link>
  );
};

export default Logo;
