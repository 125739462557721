import styled from "styled-components";

const Button = styled.div`
  border: 1px solid #d1d5db;

  background-color: ${(props) => (props.active ? "#e8ecf1" : "white")};
  border-radius: 7px;
  padding: 0.6rem 1.2rem;
  box-shadow: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export default Button;
