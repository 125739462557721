export const cabinsOptions = [
  { value: "M", label: "الدرجه الاقتصاديه " },
  { value: "W", label: "الدرجه الاقتصاديه الممتازه" },
  { value: "C", label: "درجه رجال اعمال" },
  { value: "F", label: "الدرجه الاولي" },
];
export const nationalitiesArabic = [
  "أفغاني",
  "ألباني",
  "جزائري",
  "أمريكي",
  "أندوري",
  "أنجولي",
  "أرجنتيني",
  "أرميني",
  "أسترالي",
  "نمساوي",
  "أذربيجاني",
  "باهامي",
  "بحريني",
  "بنغلاديشي",
  "بربادوسي",
  "بيلاروسي",
  "بلجيكي",
  "بليزي",
  "بنيني",
  "بوتاني",
  "بوليفي",
  "بوسني",
  "برازيلي",
  "بريطاني",
  "بروناي",
  "بلغاري",
  "بوركينابي",
  "بورمي",
  "بوروندي",
  "كمبودي",
  "كاميروني",
  "كندي",
  "كابو فيردي",
  "أفريقي",
  "تشادي",
  "تشيلي",
  "صيني",
  "كولومبي",
  "قمري",
  "كونغولي (جمهورية الكونغو الديمقراطية)",
  "كونغولي (جمهورية الكونغو)",
  "كوستاريكي",
  "كرواتي",
  "كوبي",
  "قبرصي",
  "تشيكي",
  "دانماركي",
  "جيبوتي",
  "دومينيكاني (جمهورية الدومينيكان)",
  "هولندي",
  "تيموري",
  "إكوادوري",
  "مصري",
  "إماراتي",
  "غينيا الاستوائية",
  "إريتري",
  "إستوني",
  "إثيوبي",
  "فيجي",
  "فيليبيني",
  "فنلندي",
  "فرنسي",
  "جابوني",
  "غامبي",
  "جورجي",
  "ألماني",
  "غاني",
  "يوناني",
  "غرينادي",
  "غواتيمالي",
  "غيني",
  "غياني",
  "هايتي",
  "هندوراسي",
  "هنغاري",
  "آيسلندي",
  "هندي",
  "إندونيسي",
  "إيراني",
  "عراقي",
  "إيرلندي",
  "إسرائيلي",
  "إيطالي",
  "إيفواري",
  "جامايكي",
  "ياباني",
  "أردني",
  "كازاخستاني",
  "كيني",
  "كيريباتي",
  "كويتي",
  "قيرغيزستاني",
  "لاوي",
  "لاتفي",
  "لبناني",
  "ليبيري",
  "ليبي",
  "ليختنشتايني",
  "ليتواني",
  "لوكسمبورغي",
  "مقدوني",
  "مدغشقري",
  "مالاوي",
  "ماليزي",
  "مالديفي",
  "مالي",
  "مالطي",
  "مارشالي",
  "موريتاني",
  "موريشيوسي",
  "مكسيكي",
  "ميكرونيزي",
  "مولدوفي",
  "موناكو",
  "منغولي",
  "الجبل الأسود",
  "مغربي",
  "موزمبيقي",
  "ناميبي",
  "ناوري",
  "نيبالي",
  "نيوزيلندي",
  "نيكاراغوي",
  "نيجيري",
  "نيجيري",
  "كوري (جمهورية كوريا الشمالية)",
  "نرويجي",
  "عماني",
  "باكستاني",
  "بالاوي",
  "فلسطيني",
  "بنمي",
  "بابوا غينيا الجديدة",
  "باراغواي",
  "بيروفي",
  "بولندي",
  "برتغالي",
  "قطري",
  "روماني",
  "روسي",
  "رواندي",
  "لوسياني",
  "سلفادوري",
  "ساموا",
  "سان ماريني",
  "ساو تومي",
  "سعودي",
  "سنغالي",
  "صربي",
  "سيشيلي",
  "سيراليوني",
  "سنغافوري",
  "سلوفاكي",
  "سلوفيني",
  "جزر سليمان",
  "صومالي",
  "جنوب أفريقي",
  "كوري (جمهورية كوريا الجنوبية)",
  "جنوب السوداني",
  "إسباني",
  "سريلانكي",
  "سوداني",
  "سوري",
  "طاجيكي",
  "تنزاني",
  "تايلاندي",
  "توجولي",
  "تونجي",
  "ترينيدادي أو توباغو",
  "تونسي",
  "تركي",
  "تركماني",
  "توفالو",
  "أوغندي",
  "أوكراني",
  "أوروغواي",
  "أوزبكي",
  "فانواتو",
  "فاتيكان",
  "فنزويلي",
  "فيتنامي",
  "يمني",
  "زامبي",
  "زيمبابوي",
];

export const navList = [
  { name: "الرئيسه", route: "" },
  { name: "عروضا", route: "offers" },
  { name: "من نحن", route: "about" },
  { name: "تواصل معنا", route: "contact" },
];
export const navListEn = [
  { name: "Home", route: "" },
  { name: "Offers", route: "offers" },
  { name: "About Us", route: "about" },
  { name: "contact Us", route: "contact" },
];

export const wayOptions = [
  {
    label: "ذهاب",
    value: "no-return",
  },
  {
    label: "عوده",
    value: "return",
  },
];

export const filterOptions = [
  { label: "موصي به", value: "" },
  { label: "الارخص", value: "price" },
  { label: "أقصر مدة", value: "duration" },
  { label: " الجوده", value: "quality" },
  { label: "خيارات اخرى", value: "date" },
];

export const InputBooking = [
  { name: "Name", placeholder: "الاسم", type: "text" },
  { name: "Phone", placeholder: "الهاتف", type: "text" },
  { name: "Email", placeholder: "البريد الأكتروني", type: "email" },
  { name: "Message", placeholder: "بعض التفاصيل", type: "text" },
];
export const listProgram = [
  {
    Program: "inclusions",
    heading: "تشمل",
  },
  {
    Program: "exclusions",
    heading: "لاتشمل",
  },
  {
    Program: "price",
    heading: "الاسعار",
  },
];
export const handleSeconds = (event) => {
  const hours = Math.floor(event / 3600);
  const remainingSeconds = event % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  return { hours: hours, minutes: minutes };
};

export const HandelDay = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  // Get the day
  const day = dateTime.toLocaleDateString("ar", { weekday: "long" });
  // Get the history
  // const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1; // Adding 1 because months are zero-based
  const date = dateTime.getDate();
  const history = `${day} ${month}/${date} `;
  return history;
};

export const HandelHoures = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  let hours = dateTime.getUTCHours();
  const minutes = dateTime.getUTCMinutes();
  let designation = "";

  const arabicDesignations = {
    AM: "ص",
    PM: "م",
  };

  if (hours >= 12) {
    designation = arabicDesignations.PM;
    if (hours > 12) {
      hours -= 12;
    }
  } else {
    designation = arabicDesignations.AM;
    if (hours === 0) {
      hours = 12;
    }
  }
  const time = `${hours}:${minutes} ${designation}`;
  return time;
};

export const PassengerType = {};

export const optionStopovers = [
  { value: "", label: "اي" },
  { value: "0", label: "مباشر" },
  { value: "1", label: "توقف واحد" },
  { value: "2", label: "  توقفات" },
];

export const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

export function calculateDuration(startDate, endDate) {
  // Convert the date strings to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const diff = Math.abs(end - start);

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
}
