import React, { useRef, useState } from "react";
import { countriesPhones } from "../utils/helper";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import FormRow from "./FormRow";
import styled from "styled-components";
const Error = styled.span`
  font-size: 1rem;
  color: #b91c1c;
  position: absolute;
  bottom: -10px;
`;
export default function PhoneInput({ name, label, register, error, t }) {
  return (
    <div className="w-full relative">
      <FormRow label={label} error={error}>
        <div className="flex items-center gap-3 w-full">
          <PhoneSelect id={"phone"} />
          <Input
            id={"phone"}
            className="w-full"
            {...register("phone", {
              required: t("customerForm.phone.required"),
            })}
            err={error["phone"] ? false : true}
            placeholder={t("customerForm.phone.placeholder")}
          />
        </div>
      </FormRow>
      {error && <Error>{error["phone"]?.message}</Error>}
    </div>
  );
}

const PhoneSelect = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const refInput = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("+966");
  const [codePhone, setCodePhone] = useState("+966");
  // Function to handle the change in the search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Filter the countries based on the search term
  const filteredCountries = countriesPhones.filter((phone) => {
    if (searchTerm) {
      return (
        phone["en"].toLowerCase().includes(searchTerm.toLowerCase()) ||
        phone["ar"].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return phone;
  });

  return (
    <>
      <div
        className="rounded-md h-11 xl:w-[100px] max-w-[120px] w-fit  flex items-center justify-center relative"
        onClick={() => {
          setIsActive(true);
          setSearchTerm("");
        }}
      >
        <Input
          ref={refInput}
          handleActive
          value={searchTerm}
          onChange={handleSearchChange}
          readOnly={!isActive}
          err={true}
        />
        {/* <span className=" absolute right-0 px-5">{codePhone}</span> */}
        {/* {isActive ? (
    
        ) : (
          <span className={`font-medium text-gray-700 `}>{phone}</span>
        )} */}
      </div>
      <ul
        className={`${
          isActive ? "w-full max-h-[200px] " : "w-0 h-0 border-0"
        }  bg-white  overflow-y-auto absolute left-0 top-[110%] z-10  flex flex-col`}
      >
        {filteredCountries.map((phone) => (
          <li
            value={phone.phone}
            className=" cursor-pointer hover:bg-gray-100 px-3 py-3 font-medium text-gray-700 text-sm"
            key={phone.code}
            onClick={() => {
              setSearchTerm(phone.phone);
              setIsActive(true);
            }}
          >
            {phone[language]}
            <span className="mr-2 text-gray-900 text-sm font-semibold">
              {phone.phone}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};
